<template>
    <div>
        <el-card>
            <nav class="input" style="width: auto">
                <el-input v-model="search.keyword" size="medium" placeholder="姓名，手机，邮箱，公司" />
            </nav>
            <nav class="input flex-align" style="width: auto">
                <label for="">城市</label>
                <region class="ml" :val1.sync="search.province" :val2.sync="search.city"></region>
            </nav>
            <nav class="input" style="width: 150px">
                <el-select v-model="search.type" placeholder="类型" size="medium">
                    <el-option label="全部" :value="-1" />

                    <el-option
                        v-for="(item, index) in config.speakerType"
                        :key="index"
                        :label="item.value"
                        :value="item.key"
                    />
                </el-select>
            </nav>
            <nav class="input" style="width: 150px">
                <el-select v-model="search.business" placeholder="行业" size="medium">
                    <el-option label="全部" :value="-1" />
                    <el-option
                        v-for="(item, index) in config.speakerBusiness"
                        :key="index"
                        :label="item.value"
                        :value="item.key"
                    />
                </el-select>
            </nav>

            <nav class="input" style="width: 150px">
                <el-select v-model="search.waId" placeholder="小助手" size="medium">
                    <el-option label="全部" :value="-1" />
                    <el-option
                        v-for="(item, index) in $store.state.NormalList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                </el-select>
            </nav>
            <nav class="input" style="width:auto">
                <fuzzy-selection width="420px" :value.sync="search.activityId" :type="4" :tipName="'活动'">
                </fuzzy-selection>
            </nav>
            <nav class="input" style="width: 150px">
                <el-select v-model="search.cooperated" placeholder="是否合作" size="medium">
                    <el-option label="全部" :value="-1" />
                    <el-option
                        v-for="(item, index) in config.cooperated"
                        :key="index"
                        :label="item.value"
                        :value="item.key"
                    >
                    </el-option>
                </el-select>
            </nav>

            <nav class="input" style="width: 150px">
                <el-select v-model="search.cert" placeholder="认证状态" size="medium">
                    <el-option label="全部" :value="-1" />
                    <el-option :label="'已认证'" :value="1" />
                    <!-- <el-option :label="'未认证'" :value="2" /> -->
                </el-select>
            </nav>

            <nav class="input" style="width: 150px">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="subimt">{{ "搜索" }}</el-button>
            </nav>

            <nav class="input" style="width: auto">
                <el-button type="primary" size="small" @click="add">{{ "创建嘉宾" }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <el-button
                    type="primary"
                    size="small"
                    @click="download('https://admin.yfchuhai.com/xlsx/20220726/template.xlsx')"
                    >{{ "下载导入模板" }}</el-button
                >
            </nav>
            <nav class="input" style="width: auto">
                <el-button type="primary" size="small" @click="onImport">{{ "批量导入" }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <el-button :loading="excelLoad" type="primary" size="small" @click="beforedown">{{
                    "导出EXCEL"
                }}</el-button>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                <el-table-column label="ID" prop="id"> </el-table-column>
                <el-table-column label="姓名" prop="realName"> </el-table-column>
                <el-table-column label="公司" prop="company"> </el-table-column>
                <el-table-column label="类型·行业" prop="typeName">
                    <template slot-scope="{ row }">
                        {{ row.typeName }}
                        <block v-if="row.businessName && row.typeName">·</block>{{ row.businessName }}
                    </template>
                </el-table-column>
                <el-table-column label="职位·级别" prop="job">
                    <template slot-scope="{ row }">
                        {{ row.jobLevelName }}
                        <block v-if="row.job">·{{ row.job }}</block>
                    </template>
                </el-table-column>
                <el-table-column label="城市" prop="city">
                    <template slot-scope="{ row }">
                        {{ row.province }}
                        <block v-if="row.city">·{{ row.city }}</block>
                    </template>
                </el-table-column>
                <el-table-column label="公司/个人介绍">
                    <template slot-scope="{ row }">
                        {{ row.desc }}
                    </template>
                </el-table-column>
                <el-table-column label="合作&认证状态">
                    <template slot-scope="{ row }">
                        <!-- <el-tag
              style="margin-bottom: 5px;"
              size="small"
              :type="row.cooperated == 1 ? 'warning' : 'danger'"
              v-if="row.cooperated"
            >
              {{ row.cooperated == 1 ? '合作过' : '未合作过' }}</el-tag
            > -->
                        <el-select size="medium" v-model="row.cooperated" @change="toChoose(row, $event)">
                            <el-option
                                v-for="(item, index) in config.cooperated"
                                :key="index"
                                :label="item.value"
                                :value="item.key"
                            >
                            </el-option>
                        </el-select>
                        <el-tag
                            size="small"
                            style="margin-top: 5px;"
                            :type="row.cert == 1 ? 'warning' : 'danger'"
                            v-if="row.cert"
                        >
                            {{ row.cert == 1 ? "已认证" : "未认证" }}</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column label="小助手">
                    <template slot-scope="{ row }">
                        <el-select
                            v-model="row.waId"
                            placeholder="小助手"
                            multiple
                            :multiple-limit="3"
                            size="medium"
                            @change="chagne(row)"
                            @visible-change="visibleChange"
                            @remove-tag="visibleChange()"
                        >
                            <el-option
                                v-for="(item, index) in $store.state.NormalList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id + ''"
                            />
                        </el-select>
                        <p>{{ row.userAssistantName }}</p>
                        <p>{{ row.userGuestAssistantName }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="活动" width="210">
                    <template slot-scope="{ row }">
                        <el-tag
                            v-for="item in !!row.activityName && row.activityName.split(',')"
                            :key="item"
                            type="info"
                            size="mini"
                            effect="dark"
                            >{{ item }}</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column label="备注">
                    <template slot-scope="{ row }">
                        <remark
                            :row="row"
                            :permissions="true"
                            :val="{
                                url: '/admin/InsideSpeaker/editRemark',
                                pw: 'user_list_remark',
                                user:'是否将备注同步至微信会员和内部会员库'
                            }"
                            :getlist="getlist"
                        ></remark>
                        <div class="uplist">
                            <el-image
                                style="width: 40px;height: 40px;margin:4px"
                                v-for="(item, index) in row.remarkPictures"
                                :key="index"
                                :preview-src-list="row.remarkPictures"
                                :src="item"
                            >
                            </el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="createTime"> </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="{ row }">
                        <el-button
                            v-hasShow="'inside_speaker_edit_remark'"
                            @click="modifyTheRemark(row)"
                            type="text"
                            icon="el-icon-folder-add"
                            size="mini"
                            style="color:tan"
                            >修改备注
                        </el-button>
                        <el-button
                            v-if="row.document"
                            @click="download($store.getters.imgCol + row.document)"
                            type="text"
                            icon="el-icon-download"
                            size="mini"
                            style="color:pink"
                            >下载资料
                        </el-button>
                        <el-button
                            v-hasShow="'inside_speaker_view_contact'"
                            type="text"
                            size="mini"
                            icon="el-icon-camera"
                            style="color:#003366"
                            @click="information(row)"
                            >查看联系方式</el-button
                        >
                        <el-button
                            v-hasShow="'inside_speaker_edit'"
                            type="text" icon="el-icon-edit" size="mini" style="color:skyblue" @click="add(row)"
                            >编辑</el-button
                        >
                        <el-button
                            v-hasShow="'inside_speaker_del'"
                            @click="open(row.id)" type="text" icon="el-icon-delete" size="mini" style="color:red"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="search.page"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="search.pageSize"
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
            />
        </el-card>
<!--      手机号码验证-->
      <el-dialog title="" :visible.sync="dioA" width="30%" @close="">
        <Show @form="checkf" />
        <span slot="footer">
                <el-button @click="dioA = false">取消</el-button>
                <el-button type="primary" @click="derivedForm">{{ btn ? "导出" : "导出中.." }}</el-button>
            </span>
      </el-dialog>
        <!-- 弹窗 -->
        <pop ref="pop" title="备注" @toAudit="mRemark">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="文字">
                    <el-input v-model="form.remark"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <uplist :action="action" :list.sync="form.remarkPictures" @changes="c_img"> </uplist>
                </el-form-item>
            </el-form>
        </pop>
        <el-dialog title="信息" :visible.sync="showInfo" width="30%">
            <el-form ref="form" :model="form_show" label-width="80px" :inline="false" size="normal">
                <el-form-item label="手机号">
                    <span>{{ form_show.phone }}</span>
                </el-form-item>
                <el-form-item label="邮箱">
                    {{ form_show.email }}
                </el-form-item>
                <el-form-item label="企业邮箱">
                    {{ form_show.businessEmail }}
                </el-form-item>
                <el-form-item label="微信">
                    <span>{{ form_show.weixin }}</span>
                </el-form-item>
                <el-form-item label="公司地址">
                    <span>{{ form_show.companyAddress }}</span>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
import region from "../../../components/global/region.vue";
import FuzzySelection from "../../../components/fuzzySelection.vue";
import remark from "@/components/remark/index.vue";
import uplist from "@/components/uplist/index";
import Show from "@/components/countDowns/show.vue";
export default {
    mixins: [list],
    data() {
        return {
          // 导出excel手机号码验证
          dioA:false,
          btn: true,
          row:{},
            search: {
                keyword: "",
                page: 1,
                pageSize: 10,
                type: "",
                business: "",
                province: "",
                city: "",
                cooperated: "",
                activityId: "",
                cert: "",
            },
            showInfo: false,
            form_show: {},
            excelLoad: false,
            config: {},
            action: {
                action: "speaker",
            },
            form: {},
          form_3:{}
        };
    },
    created() {
        this.getlist();
        this.getConfing();

    },
    components: {
      Show,
        region,
        FuzzySelection,
        remark,
        uplist,
    },
    methods: {
      checkf(val) {
        this.form_3 = val;
        // console.log(this.form_3)
      },
        add(val) {
            let str = "/inside_edit";
            if (val.id) {
                str += `?id=${val.id}`;
            }
            this.$router.push(str);
        },
        async information(val) {
            let { data: res } = await this.$http.get("/admin/InsideSpeaker/getContact", {
                params: {
                    id: val.id,
                },
            });
            this.showInfo = true;
            this.form_show = res.data;
        },
      async beforedown() {
        // const phone = this.$cookies.get('phone')
        const phone = "15080329619";
        if (!phone) {
          this.$message.error("没有绑定手机号");
          return;
        }
        if (!this.proof) {
          this.dioA = true;
        } else {
          this.download();
        }
      },
      // async download(val) {
      //   if (this.btn) {
      //     this.btn = false;
      //     // const { data: res } = await this.$http.get(
      //     //   '/admin/Common/checkSmsCode',
      //     //   {
      //     //     params: {
      //     //       ...this.form_3
      //     //     }
      //     //   }
      //     // )
      //     console.log(val);
      //     // console.log(this.btn)
      //     // this.$forceUpdate()
      //     if (false) {
      //       // this.$message.error(res.message)
      //       // return
      //     } else {
      //       const { data: res } = await this.$http.get("/admin/UserGuest/exportExcel", {
      //         params: {
      //           ...this.form_2,
      //           ...this.form_3,
      //         },
      //       });
      //       // console.log();
      //       if (res.errorCode == 200) {
      //         this.proof = true;
      //       }
      //
      //       // console.log(1);
      //       this.btn = true;
      //
      //       // console.log(msg.data.url);
      //       // let url = window.URL.createObjectURL(msg.data) //表示一个指定的file对象或Blob对象
      //       // console.log(url,"看一下这是啥")
      //       // let a = document.createElement('a')
      //       // document.body.appendChild(a)
      //       // console.log(msg.data)
      //       // let url = msg.data.url
      //       // console.log(url)
      //       if (res.errorCode == 200) {
      //         this.$message.success(res.message);
      //         location.href = res.data.url;
      //         this.dioA = false;
      //       }
      //       // a.href = url
      //       // a.download //命名下载名称
      //       // a.click() //点击触发下载
      //       // window.URL.revokeObjectURL(url)
      //       // this.dioA = false
      //     }
      //   }
      // },
        download(val) {
            //   location.href = val
            const link = document.createElement("a");
            link.href = val;
            link.target = "_blank";
            link.click();
            link.remove();
            //   this.$http
            //     .get('http://p2.so.qhmsg.com/t016aa441640d0957ad.jpg', {
            //       responseType: 'blob'
            //     })
            //     .then(response => {
            //       const blob = new Blob([response.data])
            //       const link = document.createElement('a')
            //       link.href = URL.createObjectURL(blob)
            //       link.download = '下载'
            //       link.click()
            //       URL.revokeObjectURL(link.href)
            //     })
            //     .catch(console.error)
        },
        onImport() {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = ".xlsx";
            input.click();
            let that = this;
            input.onchange = async function() {
                var file = this.files[0];
                var formData = new FormData();
                formData.append("file", file);
                const { data: res } = await that.$http.post("/admin/InsideSpeaker/importExcel", formData, {
                    timeout: 5000000,
                });
                if (res.errorCode == 200) {
                    that.$message.success(res.message);
                    that.getlist();
                }
            };
        },
        open(id) {
            this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$http
                        .post("/admin/insideSpeaker/del", {
                            id: id,
                        })
                        .then((res) => {
                            if (res.data.errorCode == 200) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                                if (this.total % 10 == 1) {
                                    this.search.page--;
                                }
                                this.getlist();
                            }
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        async toChoose(val) {
            let { data: res } = await this.$http.post("/admin/InsideSpeaker/editCooperated", val);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
            }
        },
        modifyTheRemark(row) {
            this.form = {
                ...row,
                remarkPictures: row.remarkPictures.map((item) => {
                    return {
                        name: item,
                        url: item,
                    };
                }),
            };
            this.$refs.pop.show = true;
        },
        async mRemark() {
            const { data: res } = await this.$http.post("/admin/InsideSpeaker/editRemark", this.form);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$refs.pop.show = false;
                this.getlist();
            }
        },
        c_img(val) {
            // console.log(val);
            var arr = val.map((item) => {
                return item.url;
            });
            this.$http.post("/admin/InsideSpeaker/editRemarkPictures", {
                id: this.form.id,
                remarkPictures: arr.toString(),
            });
        },
        async getConfing() {
            const { data: config } = await this.$http.get("/admin/Common/getConfigDictionaries?config=speaker");
            this.config = config.data;
        },
      visibleChange(e){
        if(e){
          return
        }else {
          const post = async (type)=>{
            const { data: res } = await this.$http.post("/admin/InsideSpeaker/editService", {
              ...this.row,
              waId: this.row.waId.toString(),
              sync: type
            });
            if (res.errorCode == 200) {
              this.$message.success(res.message);
              this.getlist();
            }

          }
          this.$confirm(' 是否将小助手同步至微信会员和内部会员库', '提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning'
          }).then(() => {
            post(1)


          }).catch(() => {
            post(0)


          });
        }
      },

        async chagne(row) {
          this.row=row
        },
        async derivedForm() {
          if(this.btn){
            this.btn=false
          }
          if(false){

          }else {
            const { data: res } = await this.$http.get("/admin/InsideSpeaker/exportExcel", {
              params: {
                ...this.search,
                ...this.form_3
              },
            });

            if (res.errorCode == 200) {
              this.$toUrl(res.data.url);
            }
            this.btn=true
            this.dioA=false
          }


        },
        async getlist() {
            let { data: res } = await this.$http.get("/admin/InsideSpeaker/getList", {
                params: {
                    ...this.search,
                },
            });
            this.list = res.data.list.map((item) => {
                return {
                    ...item,
                    waId: item.waId.split(",").filter(Boolean),
                    remarkPictures: item.remarkPictures.split(",").filter(Boolean),
                };
            });
            this.total = res.data.totalCount;
        },
        subimt() {
            this.search.page = 1;
            this.getlist();
        },
    },
};
</script>

<style lang="less" scoped>
.input {
    margin-right: 12px;
}
</style>
